import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].ws_server;

class MaintenanceService extends IService {
    constructor(http) {
        super(http);
    }

    async diskInfo() {
        try {
            const response = await this.http.get(`${server_base}/api/maintenance/disk_info`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async dbInfo() {
        try {
            const response = await this.http.get(`${server_base}/api/maintenance/db_info`);
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default MaintenanceService;
