var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sellers ez-view-container"},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"bar",style:({'--width':'35%'})},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"bar",style:({'--width':'25%'})},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"bar",style:({'--width':'20%'})},[_vm._m(4),_vm._m(5)]),_vm._l((10),function(seller,i){return _c('div',{staticClass:"bar",style:({'--width': '5%'})},[_vm._m(6,true),_vm._m(7,true)])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar__position"},[_c('div',{staticClass:"bar__seller-name"},[_vm._v("Abel dieguez")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar__bg"},[_c('div',{staticClass:"bar__progress bar__progress--gold"},[_c('div',{staticClass:"bar__count"},[_vm._v("30")]),_c('i',{staticClass:"ez-icon-car"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar__position"},[_c('div',{staticClass:"bar__seller-name"},[_vm._v("Abel dieguez")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar__bg"},[_c('div',{staticClass:"bar__progress bar__progress--silver"},[_c('div',{staticClass:"bar__count"},[_vm._v("30")]),_c('i',{staticClass:"ez-icon-car"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar__position"},[_c('div',{staticClass:"bar__seller-name"},[_vm._v("Abel dieguez")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar__bg"},[_c('div',{staticClass:"bar__progress bar__progress--bronze"},[_c('div',{staticClass:"bar__count"},[_vm._v("30")]),_c('i',{staticClass:"ez-icon-car"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar__position"},[_c('div',{staticClass:"bar__seller-name"},[_vm._v("Abel dieguez")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar__bg"},[_c('div',{staticClass:"bar__progress bar__progress"},[_c('div',{staticClass:"bar__count"},[_vm._v("0")]),_c('i',{staticClass:"ez-icon-car"})])])
}]

export { render, staticRenderFns }