import AvatarService from '@/services/avatar.service.js';
import AvatarUploader from '@/components/AvatarUploader/index.vue';

const service = new AvatarService();

export default {
    name: 'Avatars',
    components: { AvatarUploader },
    props: [],
    data() {
        return {
            createdModal: false,
            deleteModal: {
                shown: false,
                type: 'one',
                id: null,
            },
            loading: false,
            loadedImages: [],
            avatars: [],
            categories: [],
            uploadType: "CREATE",
            defaultType: ""
        }
    },
    computed: {},
    async mounted() {
        this.initLoad();

    },
    methods: {

        async initLoad(){
            this.loading = true;

            const [categoriesRes, avatarRes] = await Promise.all([
                service.categories(),
                service.allAvatars()
            ]);

            if (categoriesRes && !categoriesRes.error) {
                this.categories = categoriesRes.data;
            }
            if (avatarRes && !avatarRes.error) {
                this.avatars = avatarRes.data;
            }

            this.loading = false;
        },

        async onChange(data, type) {
            this.loadedImages = data;
            this.defaultType = type;
        },

        async cancelModal() {
            this.createdModal = false;
            this.initLoad();
        },

        async showDeleteModal(type, id = null) {
            this.deleteModal.id = id;
            this.deleteModal.type = type;
            this.deleteModal.shown = true;
        },

        async closeDeleteModal() {
            this.deleteModal.id = null;
            this.deleteModal.type = 'one';
            this.deleteModal.shown = false;

            return this.initLoad();

        },

        async deleteAvatar() {

            if ( this.deleteModal.type === 'one' ) {
                await service.deleteAvatar(this.deleteModal.id);
            } else {
                await service.deleteAvatars(this.deleteModal.id);
            }
            this.closeDeleteModal();

            return this.initLoad();
        },

        async upload() {
            let files = [];

            for ( let image of this.loadedImages ){
                files.push({
                    name: image.imageName,
                    id: image.imageId
                })
            }

            const res = await service.uploadAvatars(this.defaultType, files);
            if ( res && !res.error ) {
                this.loadedImages = [];
                this.cancelModal();
            }
        },

        async showModal(type, value){

            this.uploadType = type || "CREATE";
            this.defaultType = value || "";
            this.createdModal = true
        }
    }
}
