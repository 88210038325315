import {bus} from '@/helpers/bus';

import ImagePicker from '@/components/ImagePicker/index.vue';

import CompanyService from '@/services/company.service';
import UserService from "@/services/user.service";
import FileService from '@/services/file.service';
import HttpClient from "@/helpers/http.client";
import config from "@/config";

const server_base = config[config.stage].ws_server;

const http = new HttpClient();

const companyService = new CompanyService(http),
    ftpService = new FileService(http),
    userService = new UserService(http);

export default {
    name: "MyCompany",
    components: {
        ImagePicker,
    },

    data() {
        return {
            company: null,
            companyCopy: {},
            edit: {
                Avatar: false,
                Profile: false,
                Social: false,
            },
            ftpResetModal: false,
            ftpUsername: '',

            emailType: [
                {
                    text: this.$t('work'),
                    value: 'Work',
                },
                {
                    text: this.$t('home'),
                    value: 'Home',
                },
                {
                    text: this.$t('personal'),
                    value: 'Personal',
                },
                {
                    text: this.$t('other'),
                    value: 'Other',
                }
            ],

            phoneType: [
                {
                    text: this.$t('work'),
                    value: 'Work',
                },
                {
                    text: this.$t('home'),
                    value: 'Home',
                },
                {
                    text: this.$t('personal'),
                    value: 'Personal',
                },
                {
                    text: this.$t('other'),
                    value: 'Other',
                }
            ],

            timezones: [Intl.DateTimeFormat().resolvedOptions().timeZone],

            validations: {},
            picker_photo: '',
            members: [],
            allUsers: [],
            companyUsers: {},
            userSearchResults: [],
            searchFields : ['FirstName', 'LastName'],
            searchTotal: 0,
        }
    },

    computed: {
        GetPhoto() {
            return `${this.$ws_server()}/api/files/download/${this.company.CompanySettings.Logo}`;
        },
    },

    methods: {
        async savePhoto() {
            const res = await companyService.editLogo(this.company._id, this.picker_photo);

            if (res && !res.error) {
                this.edit.Avatar = false;
                this.company.CompanySettings.Logo = this.picker_photo;
            }
        },

        async editCompany() {
            const res = await companyService.edit(this.company);
            if (res && !res.error) {
                this.validations = {};
                for (const e in this.edit) this.edit[e] = false;
                this.companyCopy = JSON.parse(JSON.stringify(this.company));
                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Success',
                    text: 'Your company has been updated',
                    duration: 3 * 1000,
                })
            } else
                this.validations = res.data;
        },

        async editSettings() {
        },

        toggleEdit(key) {
            for (const e in this.edit) {
                if (e !== key) this.edit[e] = false;
            }

            this.edit[key] = !this.edit[key];

            this.company = this.companyCopy;

            this.validations = {};

            if (this.edit[key])
                this.companyCopy = JSON.parse(JSON.stringify(this.company));
        },

        async resetFTPPassword() {
            const res = await ftpService.ResetFtpPassword(this.members);

            if (res && !res.error) {
                this.ftpResetModal = false;
            }
        },

        async loadUsers() {
            const res = await userService.companyUsers();
            if (res && !res.error) {
                const users = res.data.users;
                this.allUsers = users;

                this.companyUsers = {
                    isAdmin: [],
                    isBDC: [],
                    isInventory: [],
                    isManager: [],
                    isAgent: [],
                    isAccountant: [],
                    isReception: [],
                    isUser: [],
                    isSeller: [],
                };

                for (const user of users) {
                    if (user.permissions.isAdmin) {
                        this.companyUsers.isAdmin.push(user);
                        this.members.push(user);
                    }


                    else if (user.permissions.isBDC)
                        this.companyUsers.isBDC.push(user);

                    else if (user.permissions.isInventory)
                        this.companyUsers.isInventory.push(user);

                    else if (user.permissions.isManager)
                        this.companyUsers.isManager.push(user);

                    else if (user.permissions.isAgent)
                        this.companyUsers.isAgent.push(user);

                    else if (user.permissions.isAccountant)
                        this.companyUsers.isAccountant.push(user);

                    else if (user.permissions.isReception)
                        this.companyUsers.isReception.push(user);

                    else if (user.permissions.isUser)
                        this.companyUsers.isUser.push(user);

                    else if (user.permissions.isSeller)
                        this.companyUsers.isSeller.push(user);
                }
            }
        },

        onSearchUser(results) {
            this.userSearchResults = {
                isAdmin: [],
                isBDC: [],
                isInventory: [],
                isManager: [],
                isAgent: [],
                isAccountant: [],
                isReception: [],
                isUser: [],
                isSeller: [],
            };

            for (const user of results) {

                if (user.permissions.isAdmin) {
                    this.userSearchResults.isAdmin.push(user);
                    this.searchTotal++;
                }


                else if (user.permissions.isBDC) {
                    this.userSearchResults.isBDC.push(user);
                    this.searchTotal++;
                }

                else if (user.permissions.isInventory) {
                    this.userSearchResults.isInventory.push(user);
                    this.searchTotal++;
                }

                else if (user.permissions.isManager) {
                    this.userSearchResults.isManager.push(user);
                    this.searchTotal++;
                }

                else if (user.permissions.isAgent) {
                    this.userSearchResults.isAgent.push(user);
                    this.searchTotal++;
                }

                else if (user.permissions.isAccountant) {
                    this.userSearchResults.isAccountant.push(user);
                    this.searchTotal++;
                }

                else if (user.permissions.isReception) {
                    this.userSearchResults.isReception.push(user);
                    this.searchTotal++;
                }

                else if (user.permissions.isUser) {
                    this.userSearchResults.isUser.push(user);
                    this.searchTotal++;
                }

                else if (user.permissions.isSeller) {
                    this.userSearchResults.isSeller.push(user);
                    this.searchTotal++;
                }
            }
        },

        isMember(userId) {
            return this.members.findIndex(m => m._id === userId) > -1
        },

        async addUserToNotification(member) {
            const index = this.members.findIndex(m => m._id === member._id);

            if ( index > -1 )
                this.members.splice(index, 1);
            else
                this.members.push(member);
        },

        agentAvatar(id) {
            return `${server_base}/api/files/avatar/${id}`;
        },
    },

    async mounted() {
        companyService.getTimeZones()
            .then(zones => {
                this.timezones = zones;
            })
            .catch(null);

        ftpService.GetFTPInfo()
            .then(res => {
                if (res && !res.error) {
                    this.ftpUsername = res.data.Username;
                }
            })
            .catch(null);

        this.loadUsers().then(null).catch(null);

        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));

            if (user.permissions.isRoot) {
                //TODO Root Edit Company Profile
            } else if (user.permissions.isAdmin) {
                const res = await companyService.get(user.Company._id);
                this.company = this.companyCopy = res.data;

            }
        }
    },

    async beforeCreate() {
        bus.$off('IMAGE_PICKER_CHANGE_IMAGE');
        bus.$on('IMAGE_PICKER_CHANGE_IMAGE', data => {
            this.picker_photo = data.Name;
        });
    },
}
