import MaintenanceService from "@/services/maintenance.service";
import {chartConfig} from "./gaugeChartConfig";

const service = new MaintenanceService();

export default {
    name: 'data-clear',
    components: {},
    props: [],
    data() {
        return {
            chartConfig,
            diskInfo: null,
            dbInfo: null,
            logTypes: [
                {text: 'Get', value: 'Get'},
                {text: 'Create', value: 'Create'},
                {text: 'Remove', value: 'Remove'},
                {text: 'Update', value: 'Update'},
                {text: 'Success', value: 'Success'},
                {text: 'Error', value: 'Error'},
                {text: 'Import', value: 'Import'},
                {text: 'Export', value: 'Export'},
                {text: 'All', value: 'All'}
            ],
            showModal: {
                name: ''
            },
        }
    },
    computed: {},
    mounted() {
        service.diskInfo()
            .then(res => {
                if (res && !res.error) {
                    this.diskInfo = res.data;

                    chartConfig.filestoreSeries = [(100 - (this.diskInfo.filestore.available / this.diskInfo.filestore.total) * 100).toFixed(2)]
                    chartConfig.ftpSeries = [(100 - (this.diskInfo.ftp.available / this.diskInfo.ftp.total) * 100).toFixed(2)]

                }
            })
            .catch(null);

        service.dbInfo()
            .then(res => {
                if (res && !res.error) {
                    this.dbInfo = res.data;
                }
            })
            .catch(null);
    },
    methods: {
        closeModal() {
            this.showModal.name = '';
        },

        spaceConvert(space) {
            try {
                if (space > 1024 * 1024 * 1024)
                    return (space / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
                else if (space > 1024 * 1024)
                    return (space / (1024 * 1024)).toFixed(2) + ' MB';
                else if (space > 1024)
                    return (space / (1024)).toFixed(2) + ' KB';
                else
                    return (space).toFixed(2) + ' Bytes';
            } catch (e) {
                console.error(e)
                return 0;
            }

        }
    }
}


