import {bus} from "@/helpers/bus";

import GlobalAreaChart from "@/components/Dashboards/GlobalAreaChart/index";
import CountCard from "@/components/Dashboards/CountCard/index";

export default {

  name: "SettingsDashboard",

  components: {
    GlobalAreaChart,
    CountCard,
  },

  data() {
    return {
      dateRange: "TODAY",
      dateRanges: ["ALL_TIME", "TODAY", "MONTH_TO_DAY", "LAST_MONTH"],
    }
  },

  methods: {
    setDateRange(range) {
      bus.$emit("HIDE_ALL_MODALS");
      this.dateRange = range;
    }
  }
}
