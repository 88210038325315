import { render, staticRenderFns } from "./MyCompany.html?vue&type=template&id=365d81bd&scoped=true&external"
import script from "./MyCompany.js?vue&type=script&lang=js&external"
export * from "./MyCompany.js?vue&type=script&lang=js&external"
import style0 from "./MyCompany.scss?vue&type=style&index=0&id=365d81bd&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365d81bd",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FMyCompany%2Findex.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports