import IconGalleryUploader from './Uploader/index';
import IconGalleryService from "@/services/icon.gallery.service.js";

const service = new IconGalleryService();

export default {
  name: 'IconGallery',

  components: {
    IconGalleryUploader
  },

  data() {
    return {
      createdModal: false,
      deleteModal: {
        shown: false,
        type: 'one',
        id: null,
      },
      loading: false,
      loadedImages: [],
      icons: [],
      categories: [],
      uploadType: "CREATE",
      defaultType: ""
    }
  },

  async mounted() {
    await this.initLoad();
  },

  methods: {
    async initLoad() {
      this.loading = true;

      const [categoriesRes, iconRes] = await Promise.all([
        service.categories(),
        service.allIcons()
      ]);

      if (categoriesRes && !categoriesRes.error) {
        this.categories = categoriesRes.data.categories;
      }

      if (iconRes && !iconRes.error) {
        this.icons = iconRes.data.icons;
      }

      this.loading = false;
    },

    onChange(data, type) {
      this.loadedImages = data;
      this.defaultType = type;
    },

    cancelModal() {
      this.createdModal = false;
      return this.initLoad();
    },

    showDeleteModal(type, id = null) {
      this.deleteModal.id = id;
      this.deleteModal.type = type;
      this.deleteModal.shown = true;
    },

    closeDeleteModal() {
      this.deleteModal.id = null;
      this.deleteModal.type = 'one';
      this.deleteModal.shown = false;
      return this.initLoad();
    },

    async deleteIcon() {
      this.deleteModal.type === 'one' ?
          await service.deleteIcon(this.deleteModal.id) :
          await service.deleteIcons(this.deleteModal.id);

      this.closeDeleteModal();

      return this.initLoad();
    },

    async upload() {
      let files = [];

      for (let image of this.loadedImages) {
        files.push({
          name: image.imageName,
          id: image.imageId
        })
      }

      const res = await service.uploadIcons(this.defaultType, files);
      if (res && !res.error) {
        this.loadedImages = [];
        this.cancelModal();
      }
    },

    async showModal(type, value) {
      this.uploadType = type || "CREATE";
      this.defaultType = value || "";
      this.createdModal = true
    },
  }
}