import { bus } from "@/helpers/bus";
import Dh from "@/helpers/date.helper";

import Validations from './validation';
import Errors from './Errors';

import CompanyService from '@/services/company.service';
import LeadSourceService from "@/services/lead.source.service";
import LeadSplitService from "@/services/lead.split.service";
import HttpClient from "@/helpers/http.client";

const http = new HttpClient();
const companyService = new CompanyService(http);
const leadSourceService = new LeadSourceService(http);
const leadSplitService = new LeadSplitService(http);

export default {
  name: "SplitLeads",

  data() {
    return {
      createModal: false,
      editModal: false,
      deleteModal: false,

      leadSplits: [],

      leadSplit: {
        Origin: { Company: { _id: '', Name: '' }, leadsPerRound: 2, leadSources: [] },
        Destination: { Company: { _id: '', Name: '' }, leadsPerRound: 1 },
        splitType: 'SPLIT_PER_ROUND',
        isActive: true,
      },

      companies: [],
      leadSources: [],
      splitTypes: [
        { text: 'Split per Round', value: 'SPLIT_PER_ROUND' },
        { text: 'Keep at Origin', value: 'KEEP_AT_ORIGIN' },
        { text: 'Transfer to Destination', value: 'TRANSFER_TO_DESTINATION' },
      ],

      isLoading: false,
      perPage: 25,
      totalRows: 0,
      currentPage: 1,
    }
  },

  validations: Validations,

  async mounted() {
    await this.list();
  },

  computed: { ...Errors },

  methods: {
    async openModal(type, leadSplit) {
      if ( type === 'create' ) {
        await this.getCompanies();
        this.createModal = true;
      }

      if ( type === 'edit' ) {
        await this.getCompanies();
        this.editModal = true;
        this.getLeadSources(leadSplit.Origin.Company._id);
        this.leadSplit = { ...leadSplit };
      }

      if ( type === 'delete' ) {
        this.deleteModal = true;
        this.leadSplit = { ...leadSplit };
      }
    },

    closeModal() {
      this.createModal = false;
      this.editModal = false;
      this.deleteModal = false;
      this._resetLeadSplit();
      this.leadSources = [];
    },

    _resetLeadSplit() {
      this.leadSplit = {
        Origin: { Company: { _id: '', Name: '' }, leadsPerRound: 2, leadSources: [] },
        Destination: { Company: { _id: '', Name: '' }, leadsPerRound: 1 },
        splitType: 'SPLIT_PER_ROUND',
        isActive: true,
      };
      this.$v.$reset();
    },

    _parseCompanyName() {
      this.leadSplit.Origin.Company.Name = this.companies.find(company => company._id === this.leadSplit.Origin.Company._id).Name;
      this.leadSplit.Destination.Company.Name = this.companies.find(company => company._id === this.leadSplit.Destination.Company._id).Name;
    },

    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const res = await leadSplitService.list(this.perPage, skip,);
      if ( res && !res.error ) {
        this.leadSplits = res.data.leadSplits;
        this.totalRows = res.data.count;
      }
    },

    async create() {
      await this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isLoading = true;

      this._parseCompanyName();

      const res = await leadSplitService.create(this.leadSplit);
      if ( res && !res.error ) {
        this.leadSplits.unshift(res.data.leadSplit);
        this.totalRows++;
        this.closeModal();
      }

      this.isLoading = false;
    },

    async edit() {
      await this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isLoading = true;

      this._parseCompanyName();

      const res = await leadSplitService.edit(this.leadSplit);
      if ( res && !res.error ) {
        const index = this.leadSplits.findIndex(leadSplit => leadSplit._id === this.leadSplit._id);
        this.leadSplits.splice(index, 1, this.leadSplit);
        this.closeModal();
      }

      this.isLoading = false;
    },

    async remove() {
      this.isLoading = true;

      const id = this.leadSplit._id;
      const res = await leadSplitService.remove(id);
      if ( res && !res.error ) {
        const index = this.leadSplits.findIndex(leadSplit => leadSplit._id === id);
        this.leadSplits.splice(index, 1);
        this.totalRows--;
        this.closeModal();
      }

      this.isLoading = false;
    },

    async next() {
      this.currentPage++;
      await this.list(this.currentPage);
    },

    async prev() {
      this.currentPage--;
      await this.list(this.currentPage);
    },

    async setPerPage(num) {
      this.perPage = +(num);
      bus.$emit('HIDE_ALL_MODALS');
      await this.list();
    },

    async getCompanies() {
      const res = await companyService.list(50, 0);
      if ( res && !res.error ) {
        this.companies = res.data.companies;
      }
    },

    async getLeadSources(companyId) {
      const res = await leadSourceService.list(100, 0, { companyId });
      if ( res && !res.error ) {
        this.leadSources = res.data.sources;
      }
    },
  },

  filters: {
    updatedAt(date) {
      return Dh.parseDate(date);
    }
  }
}