export default {
  originCompanyError() {
    if ( !this.$v.leadSplit.Origin.Company._id.$anyError ) return null;
    else if ( !this.$v.leadSplit.Origin.Company._id.required ) return "required_field";
    else return null;
  },
  originLeadsPerRoundError() {
    if ( !this.$v.leadSplit.Origin.leadsPerRound.$anyError ) return null;
    else if ( !this.$v.leadSplit.Origin.leadsPerRound.required ) return "required_field";
    else return null;
  },
  originLeadSourcesError() {
    if ( !this.$v.leadSplit.Origin.leadSources.$anyError ) return null;
    else if ( !this.$v.leadSplit.Origin.leadSources.required ) return "required_field";
    else return null;
  },
  destinationCompanyError() {
    if ( !this.$v.leadSplit.Destination.Company._id.$anyError ) return null;
    else if ( !this.$v.leadSplit.Destination.Company._id.required ) return "required_field";
    else return null;
  },
  destinationLeadsPerRoundError() {
    if ( !this.$v.leadSplit.Destination.leadsPerRound.$anyError ) return null;
    else if ( !this.$v.leadSplit.Destination.leadsPerRound.required ) return "required_field";
    else return null;
  },
  splitTypeError() {
    if ( !this.$v.leadSplit.splitType.$anyError ) return null;
    else if ( !this.$v.leadSplit.splitType.required ) return "required_field";
    else return null;
  },
};