import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const server_base = config[config.stage].ws_server;

class LeadSplitService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async create(leadSplit) {
    try {
      const response = await this.http.post(`${ server_base }/api/lead-split`, leadSplit);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async edit(leadSplit) {
    try {
      const response = await this.http.put(`${ server_base }/api/lead-split`, leadSplit);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async get(id) {
    try {
      const response = await this.http.get(`${ server_base }/api/lead-split/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip) {
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;

      const response = await this.http.get(`${ server_base }/api/lead-split`, query);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async remove(id) {
    try {
      const response = await this.http.delete(`${ server_base }/api/lead-split/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default LeadSplitService;