import { required } from 'vuelidate/lib/validators';

export default {
  leadSplit: {
    Origin: {
      Company: {
        _id: { required },
      },
      leadsPerRound: { required },
      leadSources: { required },
    },
    Destination: {
      Company: {
        _id: { required },
      },
      leadsPerRound: { required },
    },
    splitType: { required },
  },
}