import { render, staticRenderFns } from "./Uploader.html?vue&type=template&id=46d802ea&scoped=true&external"
import script from "./Uploader.js?vue&type=script&lang=js&external"
export * from "./Uploader.js?vue&type=script&lang=js&external"
import style0 from "./Uploader.scss?vue&type=style&index=0&id=46d802ea&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d802ea",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FIconGallery%2FUploader%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports